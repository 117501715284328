var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ykc-form',{staticClass:"el-form-wrap"},[_c('div',[_c('ykc-form',{ref:"ruleForm",staticClass:"el-form-wrap",attrs:{"model":_vm.ruleForm,"rules":_vm.rules}},[_c('ykc-form-item',{attrs:{"label":"电桩型号","prop":"modelName"}},[_c('ykc-input',{attrs:{"placeholder":"请输入电桩型号","maxlength":"30"},model:{value:(_vm.ruleForm.modelName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "modelName", $$v)},expression:"ruleForm.modelName"}})],1),_c('ykc-form-item',{attrs:{"label":"电桩品牌","prop":"isName"}},[_c('ykc-radio',{attrs:{"data":[
            { id: 1, name: '已有品牌' },
            { id: 2, name: '新增品牌' },
          ]},model:{value:(_vm.ruleForm.isName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "isName", $$v)},expression:"ruleForm.isName"}})],1),(_vm.ruleForm.isName === 1)?_c('ykc-form-item',{attrs:{"label":"选择品牌","prop":"name"}},[_c('ykc-dropdown',{attrs:{"data":_vm.brandList},model:{value:(_vm.ruleForm.name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "name", $$v)},expression:"ruleForm.name"}})],1):_vm._e(),(_vm.ruleForm.isName === 2)?_c('ykc-form-item',{attrs:{"label":"新增品牌","prop":"inputName"}},[_c('ykc-input',{attrs:{"placeholder":"请输入新增品牌","maxlength":"30"},model:{value:(_vm.ruleForm.inputName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "inputName", $$v)},expression:"ruleForm.inputName"}})],1):_vm._e(),_c('ykc-form-item',{attrs:{"label":"电桩类型","prop":"pileType"}},[_c('ykc-radio',{attrs:{"data":[
            { id: 1, name: '直流' },
            { id: 2, name: '交流' },
          ]},model:{value:(_vm.ruleForm.pileType),callback:function ($$v) {_vm.$set(_vm.ruleForm, "pileType", $$v)},expression:"ruleForm.pileType"}})],1),_c('ykc-form-item',{attrs:{"label":"充电桩功率","prop":"pilePower"}},[_c('ykc-input',{attrs:{"placeholder":"请输入充电桩功率","type":"Number","maxlength":"30"},model:{value:(_vm.ruleForm.pilePower),callback:function ($$v) {_vm.$set(_vm.ruleForm, "pilePower", $$v)},expression:"ruleForm.pilePower"}},[_c('template',{slot:"append"},[_vm._v("KW")])],2)],1),_c('ykc-form-item',{attrs:{"label":"辅助电源","prop":"pileBoostVoltage"}},[_c('ykc-dropdown',{attrs:{"data":[
            { id: 1, name: '12V' },
            { id: 2, name: '24V' },
            { id: 3, name: '12&24V' },
          ]},model:{value:(_vm.ruleForm.pileBoostVoltage),callback:function ($$v) {_vm.$set(_vm.ruleForm, "pileBoostVoltage", $$v)},expression:"ruleForm.pileBoostVoltage"}})],1),_c('ykc-form-item',{attrs:{"label":"充电终端数量","prop":"pileGunNum"}},[_c('ykc-input',{attrs:{"placeholder":"请输入充电终端数量","maxlength":"30","disabled":_vm.isEdit},model:{value:(_vm.ruleForm.pileGunNum),callback:function ($$v) {_vm.$set(_vm.ruleForm, "pileGunNum", $$v)},expression:"ruleForm.pileGunNum"}})],1),_c('ykc-form-item',{attrs:{"label":"电桩国标","prop":"nationalStandard"}},[_c('ykc-radio',{attrs:{"data":[
            { id: 1, name: '国标2011' },
            { id: 2, name: '国标2015' },
          ]},model:{value:(_vm.ruleForm.nationalStandard),callback:function ($$v) {_vm.$set(_vm.ruleForm, "nationalStandard", $$v)},expression:"ruleForm.nationalStandard"}})],1),_c('ykc-form-item',{attrs:{"label":"充电模块品牌","prop":"moduleName"}},[_c('ykc-input',{attrs:{"placeholder":"请输入充电模块品牌","maxlength":"30"},model:{value:(_vm.ruleForm.moduleName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "moduleName", $$v)},expression:"ruleForm.moduleName"}})],1),_c('ykc-form-item',{attrs:{"label":"充电枪线品牌","prop":"gunLineName"}},[_c('ykc-input',{attrs:{"placeholder":"请输入充电枪线品牌","maxlength":"30"},model:{value:(_vm.ruleForm.gunLineName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "gunLineName", $$v)},expression:"ruleForm.gunLineName"}})],1),_c('ykc-form-item',{attrs:{"label":"额定电压","prop":"voltage"}},[_c('ykc-input',{attrs:{"placeholder":"请输入额定电压","type":"Number","maxlength":"30"},model:{value:(_vm.ruleForm.voltage),callback:function ($$v) {_vm.$set(_vm.ruleForm, "voltage", $$v)},expression:"ruleForm.voltage"}})],1),_c('ykc-form-item',{attrs:{"label":"额定电流","prop":"current"}},[_c('ykc-input',{attrs:{"placeholder":"请输入额定电流","type":"Number","maxlength":"30"},model:{value:(_vm.ruleForm.current),callback:function ($$v) {_vm.$set(_vm.ruleForm, "current", $$v)},expression:"ruleForm.current"}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }